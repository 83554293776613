// $primary: #a51616;
$primary: #cf008d; /* MAIN COLOR */
$secondary: #fb7941; /* SECONDARY COLOR */
$third: #61b291;
$fourth: #fe342c;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
$fontLrg : 4em;

@import 'https://fonts.googleapis.com/css?family=Fugaz+One|Yanone+Kaffeesatz';

#main {
	background: url(../img/bgMin.jpg) no-repeat center;
	background-size: cover;
	background-position: 65% 0;
	@media (max-width: 767px) {
		background-position: 55% 50%;
	}
}

.container-fluid {
	padding: 0;
}

.row {
	margin: 0;
}

.blkTxt { color: $blk; }

.marBtmSml { margin-bottom: 2em; }
.marTopSml { margin-top: 2em; }
.marTopBtm { margin: 1.5em 0em; }
.padBtm { padding-bottom: 5em; }
.noPad { padding: 0; }


h1 {
	font-size: 4em;
	// font-family: 'Fascinate Inline', sans-serif;
	font-family: 'Fugaz One', sans-serif;
	line-height: 1.5em;
	text-shadow: 2px 2px black;
}



h3 {
	line-height: 1.75em;
}

em { 
	color: $primary; 
	text-shadow: 2px 2px 0px $wht;
}

.vert {

	background: rgba($primary, 1);

	h3 { 
		font-size: 4rem;
		font-family: 'Yanone Kaffeesatz', sans-serif; 

		@media (max-width: 1000px) {
			line-height: 1.25em;
			font-size: 2.25rem;
		}	
	}
}

.vertAlign {
	display: inline-block;
	vertical-align: middle;
}

@mixin btn {
	display: inline-block;
	font-size: 1.75em;
	background: $primary;
	color: $wht;
	border: 1px solid $primary;
	padding: 0.75em 2em;
	border-radius: 5px;
	margin-top: 1em;
	margin-bottom: 1em;

	&:hover {
		background: rgba($primary, 0.5);
		color: lighten($wht, 10%);

	}
}

body {
	color: $wht;
}

h2 {
	font-size: 3em;
	font-family: 'Fugaz One', cursive;
	text-shadow: 2px 2px black;

	@media (max-width: 796px) {
		&.mobile {
			font-size: 3rem;
		}
	}
}

.btnxl {
	@include btn;
	@media (max-width: 768px) {
		padding: 10px;
	}
}

nav {
	z-index: 1000;
}



.navbar .navbar-nav {
	margin-top: 15px;
		@media (max-width: 991px) {
			margin-top: 0;
		}		
    > li > a {
		text-align: center;
		color: black;
		margin-top: 5px;
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 10%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		@include btn2;
		display: block;
		width: 75%;
	}

	input[type="submit"] {
		@include btn2;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

.navbar-default {
	background: $wht;
}
.bg {
	background: $blk;
	padding: 100px 0;
}
.bg2 {
	background: $primary;
	padding: 100px 0;
}
.bg3 {
	background: $blk;
	padding: 100px 0;
}
.icon {
	max-width: 200px;
	display: block;
	margin: 0 auto;
}
@media (max-width: 991px) {
	.icon {
		max-width: 100px;
	}
}

p {
	font-family: 'Yanone Kaffeesatz', sans-serif;
	font-size: 25px;
}
.btmTxt {
	font-size: 40px;
	text-shadow: 2px 2px black;
}
.btmTxt2 {
	font-size: 30px;
	text-shadow: 2px 2px black;
}
.transBg {
	background: rgba(108,151,94,0.5);
	border-radius: 15px;
}
@media (max-width: 1260px) {
	.vert h3 {
		font-size: 26px;
	}
}
.logo {
		max-width: 285px;
	@media (max-width: 991px) {
		max-width: 250px;
		margin-top: 5px;
	}
	@media (max-width: 768px) {
		max-width: 175px;
	}
}
.top-pad h2, .top-pad h3 {
	text-shadow: none;
}
.smTxt {
	margin-top: 50px;
}
.title {
	@media (max-width: 768px) {
		font-size: 26px;
	}
}

